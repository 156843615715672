<template>
    <div class="reset-password page-center">
        <div class="web-content">
            <div class="process-structure">
                <div class="auth-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('auth.ResetPassword.title') }}
                        </div>
                    </div>
                    <div class="form-data">
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <v-text-field
                                v-model="account"
                                outlined
                                :label="$t('auth.ResetPassword.label[0]')"
                                class="border-orange-input"
                                readonly
                                :hint="$t('auth.ResetPassword.hint[0]')"
                                persistent-hint
                            />
                            <v-text-field
                                v-model="resetForm.password"
                                :type="showPWD ? 'text' : 'password'"
                                :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
                                outlined
                                :label="$t('auth.ResetPassword.label[1]')"
                                placeholder=""
                                :hint="$t('auth.ResetPassword.hint[1]')"
                                persistent-hint
                                :rules="passwordRule"
                                class="border-orange-input"
                                @click:append="showPWD = !showPWD"
                            />
                        </v-form>
                        <div
                            v-if="!disable"
                            class="submit-btn"
                            @click="resetPassword"
                        >
                            {{ $t('auth.ResetPassword.button[0]') }}
                        </div>
                        <div
                            v-else
                            class="disable-btn"
                        >
                            {{ $t('auth.ResetPassword.button[1]') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import _ from 'lodash';
import jwt from 'jsonwebtoken';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            showPWD: false,
            account: '',
            resetForm: {
                password: '',
            },
            passwordRule: [
                (v) => !!v || this.$t('auth.ResetPassword.rule[0]'),
                (v) => (v && v.length >= 6) || this.$t('auth.ResetPassword.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('auth.ResetPassword.rule[2]'),
            ],
        };
    },
    computed: {
        token() {
            return _.get(this.$route, 'params.token');
        },
    },
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mAuth', ['Logout']),
        init() {
            if (this.token) {
                try {
                    const result = jwt.decode(this.token);
                    this.account = result.account;
                } catch {
                    this.disable = true;
                    this.setAlert({
                        message: this.$t('alertMessage.reset-link'),
                        color: 'error',
                    });
                }
            }
        },
        resetPassword() {
            this.$refs.form.validate();
            if (this.valid && !this.disable) {
                this.setLoading(true);
                API.User.ResetPassword(this.token, this.resetForm)
                    .then(() => {
                        this.setAlert({
                            message: this.$t('alertMessage.reset-login'),
                            color: 'success',
                        });
                        this.Logout();
                        this.$router.push({ name: 'Login' });
                        this.setLoading(false);
                    })
                    .catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.reset-fail'),
                            color: 'error',
                        });
                        this.setLoading(false);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-password {
  .form-data {
    margin-top: 65px;
  }
}
</style>
